<template>
    <div class="container">
        <div class="content">
            <div class="top" v-show="!firstTimeVisible">
                <div class="titleTop">Welcome</div>
                <img src="@/assets/images/login/taskman.png" alt="">
                <div class="subTitle">高指-智能工厂管理系统</div>
            </div>
            <div class="main" v-show="!firstTimeVisible">
                <div class="login">账户登录</div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                    <el-form-item label="" prop="name">
                        <el-input v-model.trim="ruleForm.name" placeholder="手机号">
                            <img slot="prefix" src="@/assets/images/login/user.png" alt="">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="" prop="password">
                        <el-input type="password" v-model.trim="ruleForm.password" placeholder="密码" show-password
                            @keydown.enter.native="onSubmit">
                            <img slot="prefix" src="@/assets/images/login/password.png" alt="">
                        </el-input>
                    </el-form-item>
                </el-form>
                <div class="forgetPassword">
                    <div @click="forgetPassword">忘记密码</div>
                </div>
                <el-button class="loginBtn" @click="onSubmit">登 录</el-button>
                <div class="more">
                    <span>其他登录方式：</span>
                    <img src="@/assets/images/login/dingding.png" alt="" @click="DDlogin">
                </div>
            </div>
            <el-dialog title="" :show-close="false" :visible.sync="dialogVisible" class="QR">
                <div class="QRcode">
                    <div id="self_defined_element" class="self-defined-classname"></div>
                    <p>请用钉钉APP</p>
                    <p>扫码二维码安全登录</p>
                    <el-button type="default" @click="QRcodeShowReturn">返回</el-button>
                </div>
            </el-dialog>
            <el-dialog title="完善登录信息" :visible.sync="firstTimeVisible" @close="closeFn" class="dialogLogin">
                <el-form :model="ruleForm2" :rules="rules" ref="ruleForm2" class="demo-ruleForm">
                    <el-form-item label="" prop="name">
                        <el-input v-model.trim="ruleForm2.name" placeholder="手机号">
                            <img slot="prefix" src="@/assets/images/login/user.png" alt="">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="" prop="password">
                        <el-input show-password type="password" v-model.trim="ruleForm2.password" placeholder="密码">
                            <img slot="prefix" src="@/assets/images/login/password.png" alt="">
                        </el-input>
                    </el-form-item>
                </el-form>
                <div class="btn">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" @click="ok">确 定</el-button>
                </div>
            </el-dialog>
            <div class="footer">
                <div>Copyright © 粤ICP备19011921号</div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { mapMutations } from 'vuex'
import { getDingTalk, getTmepDingTalk, getUserInfo, bindAccount, userLogin, userInfos, customerModuleList } from '@/api/index.js'
import { urls } from '@/utils/urls'
export default {
    name: "login",
    data() {
        return {
            rules: {
                name: [
                    { required: true, message: '该项不能为空', trigger: 'change' },
                    { min: 11, max: 11, message: '手机号输入格式有误', trigger: 'blur' },
                ],
                password: [
                    {
                        required: true, message: '该项不能为空', trigger: 'change'
                    },
                    {
                        min: 1, max: 14, message: '密码格式有误', trigger: 'blur'
                    }
                ]
            },
            dialogVisible: false,  //二维码弹框状态
            ruleForm: {
                name: '',
                password: '',
            },
            ruleForm2: {
                name: '',  //未绑定的手机号
                password: '',  //未绑定的账户密码
            },
            firstTimeVisible: false,  //首次登录弹框
            ticket: null,  //票据
        }
    },
    methods: {
    ...mapMutations('userPermission',['SET_USERNAME','SET_CUSTMODULE']),
        cancel() {
            this.firstTimeVisible = false
        },
        ok() {
            let params = {
                bindToken: this.bindTokenTemp,
                phone: this.ruleForm2.name,
                password: this.ruleForm2.password
            }
            bindAccount(params).then(res3 => {
                if (res3.code === '200') {
                    this.firstTimeVisible = false
                    this.$message.success('绑定成功！')
                } else {
                    this.$message.error(res3.msg)
                }
            })
        },
        closeFn() {
            this.firstTimeVisible = false
            this.ruleForm2.name = ''
            this.ruleForm2.password = ''
        },
        // 钉钉扫码登录
        DDlogin() {
            this.dialogVisible = true
            getDingTalk().then(res => {
                // console.log('当前打印:res', res)
                if (res.code === '200') {
                    let clientId = res.data.clientId
                    // console.log('--clientId--', clientId)
                    window.DTFrameLogin(
                        {
                            id: 'self_defined_element',
                            width: 280,
                            height: 270,
                        },
                        {
                            redirect_uri: encodeURIComponent(urls.baseUrls), 
                            client_id: clientId,
                            scope: 'openid',
                            response_type: 'code',
                            state: 'xxxxxxxxx',
                            prompt: 'consent',
                        },
                        (loginResult) => {
                            const { redirectUrl, authCode, state } = loginResult
                            console.log(redirectUrl, authCode, state)
                            let params = {
                                'authCode': authCode,
                            }
                            getTmepDingTalk(params).then(res => {
                                // console.log('获取票据--并判断是否绑定', res)
                                this.ticket = res.data
                                localStorage.setItem('ticket', this.ticket)
                                if (res.code === '200') {
                                    this.dialogVisible = false
                                    let params = {
                                        ticket: res.data,
                                        ssoLogoutCall: redirectUrl
                                    }
                                    getUserInfo(params).then(res2 => {
                                        // console.log('获得用户信息', res2)
                                        if (res2.code === '200') {
                                            localStorage.setItem('taskmanuserToken', res2.data.accessToken)
                                            this.$message.closeAll()
                                            this.$message.success('登录成功！')
                                            this.$router.push('/')
                                        }
                                    })
                                } else if (res.code === "A0113") {
                                    this.$message.closeAll()
                                    this.$message.error(res.msg)
                                    this.bindTokenTemp = res.data
                                    this.dialogVisible = false
                                    this.firstTimeVisible = true
                                } else if (res.code === 'A0112') {
                                    return
                                } else {
                                    this.$message.closeAll()
                                    this.$message.error(res.msg)
                                }
                            })
                        },
                        (errorMsg) => {
                            // 展示登录失败的具体原因
                            // alert(`Login Error: ${errorMsg}`)
                            console.log('--errorMsg--', errorMsg)
                        },
                    )
                }
            })
        },
        forgetPassword() {
            this.$router.push('/password')
        },
        QRcodeShowReturn() {
            this.dialogVisible = false
        },
        // 账号密码登录
        onSubmit() {
            this.$refs.ruleForm.validate(async valid => {
                if (valid) {
                    let param = {
                        phone: btoa(this.ruleForm.name),
                        password: btoa(this.ruleForm.password),
                        encrypted: true
                    }
                    const res = await userLogin(param)
                    // console.log('当前打印:res',res)
                    if (res.code === '200') {
                        localStorage.setItem('taskmanuserToken', res.data.accessToken)
                        this.$message.closeAll();
                        
                        const userInfosRes = await userInfos();
                        if(userInfosRes.code === '200'){
                            this.SET_USERNAME(userInfosRes.data.name);
                            this.$message.success('登录成功！');
                            // const customerLists = await customerModuleList();
                            // console.log('userInfosRes:', customerLists);
                            // if(customerLists.code === '200'){
                            //     this.SET_CUSTMODULE(customerLists.data);
                            // }
                            this.$router.push('home')
                        }
                    } else {
                        this.$message.closeAll()
                        this.$message.error(res.msg)
                    }
                }
            })
        }
    }
}
</script>
  
<style lang="sass" scoped>
@import './style/login.scss'
</style>